import { Component, OnInit } from '@angular/core';
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators
} from '@angular/forms';
import { PincodeApiService } from '@pfa/api';
import { PensionskundeStore } from 'ds-api';
import { NoConsecutiveValidator, OnlyNumbersValidator } from '@pfa/utils';
import { combineLatest } from 'rxjs';
import {
  NotificationService,
  NotificationType,
  GlobalWorkingService
} from '@pfaform';
import { switchMap } from 'rxjs/operators';

@Component({
  selector: 'mitpfa-change-pincode',
  templateUrl: './change-pincode.component.html',
  styleUrls: ['./change-pincode.component.scss']
})
export class ChangePincodeComponent implements OnInit {
  pincodeForm: UntypedFormGroup;
  updateError: boolean;

  constructor(
    private readonly pincodeApiService: PincodeApiService,
    private readonly pensionskundeStore: PensionskundeStore,
    private readonly notificationService: NotificationService,
    private readonly globalWorkingService: GlobalWorkingService
  ) {}

  ngOnInit() {
    const pincodeValidator = [
      OnlyNumbersValidator,
      NoConsecutiveValidator,
      Validators.minLength(4),
      Validators.required
    ];

    this.pincodeForm = new UntypedFormGroup({
      newPin: new UntypedFormControl('', pincodeValidator),
      repeatPin: new UntypedFormControl('', pincodeValidator)
    });
  }

  private syncPhoneToLdap() {
    return this.pensionskundeStore.pensionskundeGet().pipe(
      switchMap(customer =>
        this.pensionskundeStore.pensionskundeOpdaterMobilnrPut({
          mobilNr: customer.mobil ?? '',
          landekode: customer.landekode
        })
      )
    );
  }

  private updatePin(newPin: string, repeatPin: string) {
    return this.pincodeApiService.updatePin({
      pin: String(newPin),
      gentagPin: String(repeatPin)
    });
  }

  savePincode() {
    this.updateError = false;
    this.globalWorkingService.show();
    const newPin = this.pincodeForm.value.newPin;
    const repeatPin = this.pincodeForm.value.repeatPin;

    if (newPin !== repeatPin) {
      this.globalWorkingService.hide();
      this.notificationService.showNotification({
        message: 'DL.PW01.C10',
        type: NotificationType.ERROR
      });
      return;
    }

    combineLatest([
      this.syncPhoneToLdap(),
      this.updatePin(newPin, repeatPin),
      this.pincodeApiService.updatePinState({ state: 'UNLOCKED' })
    ]).subscribe({
      next: () => {
        this.globalWorkingService.hide();
        this.notificationService.showNotification({
          message: 'DL.PW01.C34',
          type: NotificationType.SUCCESS
        });
        this.pincodeForm.reset({ newPin: '', repeatPin: '' });
      },
      error: () => {
        this.updateError = true;
        this.globalWorkingService.hide();
      }
    });
  }
}
