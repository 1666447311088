<div class="pin-form-content">
  <div class="row">
    <div class="col-8">
      <p [coContent]="'DL.PW01.C30'" class="text--note"></p>
    </div>
  </div>

  <form
    *ngIf="pincodeForm"
    class="row spacing-unit--half-over"
    [formGroup]="pincodeForm"
  >
    <mat-form-field class="form-field-light col-sm-6 col-md-3" color="accent">
      <mat-label>{{ 'DL.PW01.C31' | content }}</mat-label>

      <input
        matInput
        [formControl]="pincodeForm.controls.newPin"
        type="password"
        maxlength="4"
      />

      <mat-error
        *ngIf="pincodeForm.controls.newPin.errors?.required"
        [coContent]="'DL.MD01.C12'"
      ></mat-error>

      <mat-error
        *ngIf="pincodeForm.controls.newPin.errors?.invalid"
        [coContent]="'DL.PW01.C11'"
      ></mat-error>
    </mat-form-field>

    <mat-form-field class="form-field-light col-sm-6 col-md-3" color="accent">
      <mat-label>{{ 'DL.PW01.C32' | content }}</mat-label>

      <input
        matInput
        [formControl]="pincodeForm.controls.repeatPin"
        type="password"
        maxlength="4"
      />

      <mat-error
        *ngIf="pincodeForm.controls.repeatPin.errors?.required"
        [coContent]="'DL.MD01.C12'"
      ></mat-error>

      <mat-error
        *ngIf="pincodeForm.controls.repeatPin.errors?.invalid"
        [coContent]="'DL.PW01.C11'"
      ></mat-error>
    </mat-form-field>
  </form>

  <button
    mat-raised-button
    [coContent]="'DL.PW01.C33'"
    [disabled]="pincodeForm?.invalid"
    (click)="savePincode()"
  ></button>

  <co-warning *ngIf="updateError">
    <span [coContent]="'DL.MD01.C161'"></span>
  </co-warning>

  <co-bodytext>
    <co-info class="pin-note text--note">
      <span coContent="DL.PW01.C43"></span>
    </co-info>
  </co-bodytext>
</div>
