import {
  Component,
  DestroyRef,
  inject,
  OnInit,
  ViewChild
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { PincodeApiService } from '@pfa/api';
import { ContentUtilService, PageTrackingService } from '@pfa/core';
import { ComponentCanDeactivate } from '@pfa/handler';
import {
  UnsavedChangesWarningComponent,
  UnsavedChangesWarningResponse
} from '@pfaform';
import { combineLatest, Observable } from 'rxjs';
import { first, map } from 'rxjs/operators';
import { ChangePincodeComponent } from './change-pincode/change-pincode.component';
import { PensionsKundeGenerelleData, PensionskundeStore } from 'ds-api';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'mitpfa-pincode',
  templateUrl: './pincode.component.html',
  styleUrls: ['./pincode.component.scss']
})
export class PincodeComponent implements OnInit, ComponentCanDeactivate {
  private readonly dialog: MatDialog = inject(MatDialog);
  private readonly pincodeApiService: PincodeApiService =
    inject(PincodeApiService);
  private readonly pensionskundeStore: PensionskundeStore =
    inject(PensionskundeStore);
  private readonly contentUtil: ContentUtilService = inject(ContentUtilService);
  private readonly destroyRef: DestroyRef = inject(DestroyRef);
  private readonly pageTrackingService: PageTrackingService =
    inject(PageTrackingService);

  @ViewChild(ChangePincodeComponent)
  changePincode!: ChangePincodeComponent;

  public pinInit: boolean;
  public pinSwitchChecked: boolean;
  public pinSwitchDisabled: boolean;
  public pinSwitchText: string;
  public pinPhoneText: string;
  public pinLinkText: string;
  public pensionCustomer: PensionsKundeGenerelleData;

  public ngOnInit(): void {
    this.pinInit = false;
    this.pinSwitchChecked = false;

    combineLatest({
      customer: this.pensionskundeStore.pensionskundeGet(),
      pin: this.pincodeApiService.getPinState()
    })
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(obs => {
        this.pinInit = true;
        this.pensionCustomer = obs.customer;
        this.pageTrackingService.trackContentLoaded();

        if (obs.customer.mobil && obs.customer.mobil.length) {
          this.pinSwitchChecked = obs.pin.state === 'LOCKED' ? false : true;
          this.pinSwitchText =
            obs.pin.state === 'LOCKED' ? 'DL.PW01.C39' : 'DL.PW01.C40';
          this.pinSwitchDisabled = false;
          this.pinPhoneText = this.contentUtil.getContent('DL.PW01.C46', [
            obs.customer.mobil
          ]);
          this.pinLinkText = 'DL.PW01.C47';
        } else {
          this.pinSwitchChecked = false;
          this.pinSwitchDisabled = true;
          this.pinSwitchText = 'DL.PW01.C39';
          this.pinPhoneText = 'DL.PW01.C44';
          this.pinLinkText = 'DL.PW01.C45';
        }
      });
  }

  public toggleSwitch(event): void {
    this.pinSwitchChecked = event.checked;
    this.pinSwitchText = event.checked ? 'DL.PW01.C39' : 'DL.PW01.C40';

    if (!event.checked) {
      this.pincodeApiService.updatePinState({ state: 'LOCKED' }).subscribe();
    }
  }

  public canDeactivate(): Observable<boolean> | boolean {
    if (!this.pinSwitchChecked) {
      return true;
    }

    if (this.changePincode.pincodeForm.dirty) {
      return this.dialog
        .open(UnsavedChangesWarningComponent)
        .afterClosed()
        .pipe(
          map(
            (result: UnsavedChangesWarningResponse) =>
              result && result.result === 'ACCEPT'
          ),
          first()
        );
    } else {
      return true;
    }
  }
}
